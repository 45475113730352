const axios = require("axios");
const path = require("path");

//get URL of script's hosted location
const currentScriptSrc = new URL(document.currentScript.src);


//get custId from script
var custId = null;
if(document.currentScript.getAttribute("nova-custId")){
  custId = document.currentScript.getAttribute("nova-custId");
}

var localUrl = null;
if(custId){
  var urlPath = "/" + custId;
  localUrl = currentScriptSrc.href.replace('/bundle.js',urlPath)
}else{
  localUrl = currentScriptSrc.href.replace('/bundle.js','')
}

const localAPI = axios.create({
  baseURL: localUrl,
  timeout: 10000,
});

async function getProperties() {
  var apiResponse = await localAPI.get(
    `botProperties.json`,
    {headers:{'Access-Control-Allow-Origin':"*"}}
  );

  return apiResponse;
}


export { localAPI, getProperties};

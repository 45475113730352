import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./App";
import { getAuth } from "./utils/authProvider";
import { getProperties } from "./utils/localApi";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import "./index.css";
import "regenerator-runtime/runtime";

//injecting the div for the bot into our index.html
const root = document.createElement("div");
root.setAttribute("id", "novaBot");
document.currentScript.parentElement.appendChild(root);

//get custId from div attributes if it exists
var custId = null;
if (document.currentScript.getAttribute("nova-custId")) {
  custId = document.currentScript.getAttribute("nova-custId");
}

async function asyncCall() {
  const result = await getAuth();
  var properties = await getProperties();
  properties = properties.data;

  if (properties.generalSettings.ui.viewStyle == "embed") {
    root.style.height = "inherit";
    root.style.width = "inherit";
    root.style.top = "inherit";
    root.style.bottom = "inherit";
    root.style.left = "inherit";
    root.style.right = "inherit";
    root.style.position = "fixed";
  }

  //add custom css link to site if it exists
  var customCSSUrl = null;
  if (custId) {
    customCSSUrl =
      properties.generalSettings.rootSite + "/" + custId + "/novaBotStyles.css";
  } else {
    customCSSUrl = properties.generalSettings.rootSite + "/novaBotStyles.css";
  }

  const cssFetch = await fetch(customCSSUrl);
  if (cssFetch.ok) {
    var customCSS = document.createElement("link");
    customCSS.rel = "stylesheet";
    customCSS.type = "text/css";
    customCSS.href = customCSSUrl;
    document.body.appendChild(customCSS);
    // console.log(document.styleSheets);
  } else {
    console.log("No Custom Styling File Added");
  }

  ReactDOM.render(
    properties.generalSettings.adEnabled ? (
      <AzureAD provider={result.AuthProviderLogin} forceLogin={true}>
        <App />
      </AzureAD>
    ) : ( 
        <App />
    ),
    document.getElementById("novaBot")
  );
}

asyncCall();

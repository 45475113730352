import React from 'react';

import './index.css';

import { getCirclePath } from './utils';

interface ITextCurve {
  text: string;
  objectSize?: number;
  spacing?: number;
  offset?: number;
  overlap?: boolean;
  children?: React.ReactChild;
  startTextOffset?: number;
  textCurveDirection?: 'clockwise' | 'counterClockwise';
}

TextCurve.defaultProps = {
  objectSize: 130,
  spacing: 12,
  offset: 15,
  startTextOffset: '10',
  textCurveDirection: 'clockwise',
};

function TextCurve(props: ITextCurve) {
  const { objectSize, overlap, offset, text, children, textCurveDirection, startTextOffset } = props;

  const diameter = objectSize;
  const radius = objectSize / 2;
  const isClockwise = textCurveDirection === 'clockwise' ? 1 : 0;

  return (
    <div className='novaBotTextCurveContainer'>
      <div
        className='novaBotTextCurve'
        style={{
          marginBottom: overlap ? `-${radius}px` : '0',
          width: `${diameter}px`,
          height: `${diameter}px`,
        }}
      >
        <svg width={diameter} height={diameter}>
          <path id='curve' d={getCirclePath(offset, radius, radius, isClockwise ? true : false)} />
          <text>
            <textPath className='novaBotText' href='#curve' startOffset={`${startTextOffset}%`}>
              {text}
            </textPath>
          </text>
        </svg>
      </div>
      {children}
    </div>
  );
}

export default TextCurve;

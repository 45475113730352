import React, { useState,useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, GridDirection, GridJustification } from '@material-ui/core';
import parse from 'html-react-parser';
import { getProperties } from "../../../utils/localApi";

interface IBotAttentionGrabberMsgBox {
  botGridDirection: GridDirection;
  botGridJustify: GridJustification;
  botMsgVisibleVar: string;
  iconStyle: any;
  botMsgVisible: any;
  botPromptMsg: string;
  botMsgStyle: any;
  iconUrl: string;
  botMsgExitStyle: any;
  onClick: (event: any) => void;
  custId?: any;
}

function BotAttentionGrabberMsgBox(props: IBotAttentionGrabberMsgBox) {
  const {
    botGridDirection,
    botGridJustify,
    botMsgVisibleVar,
    iconStyle,
    botMsgVisible,
    botPromptMsg,
    botMsgStyle,
    iconUrl,
    botMsgExitStyle,
    onClick,
    custId,
  } = props;
  const [closeButtonIcon,setCloseButtonIcon]=useState<string>(null);

  useEffect(()=>{
    getProperties()
    .then(result => {
      if (custId && custId != "anon") {
        setCloseButtonIcon(
          result.data.generalSettings.rootSite +
          "/" +
          custId +
          "/close-round.svg"
        );
      } else {
        setCloseButtonIcon(
          result.data.generalSettings.rootSite + "/close-round.svg"
        );
      }
    });
  },[custId])


  
  return (
    <>
      <Grid container direction={botGridDirection} justifyContent={botGridJustify} alignItems='center' spacing={1}>
        <Grid component={Box} className='novaBotIcon' item xs={3} md={1} style={{ display: botMsgVisibleVar }}>
          <div id='novaBotIconImg' onClick={onClick}>
            <img className='novaBotIconImg' alt='bot icon' style={iconStyle} src={iconUrl} />
          </div>
        </Grid>
        <Grid component={Box} item md={5} xs={8} className='novaBotMsg' style={{ display: botMsgVisibleVar }}>
          <div id='novaBotMsg' style={botMsgStyle}>
            <p>{parse(botPromptMsg)}</p>
            <div id='novaBotMsgExit'>
              <img className='novaBotMsgExit' src={closeButtonIcon} style={botMsgExitStyle} alt="close Button msg box" />
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        {!botMsgVisible.value && botMsgVisibleVar == 'none' ? (
          <Grid container direction={botGridDirection} justifyContent={botGridJustify} alignItems='center' spacing={1}>
            <Grid component={Box} className='novaBotIcon' item xs={3} md={1}>
              <div id='novaBotIconImg' onClick={onClick}>
                <img className='novaBotIconImg' alt='bot icon' style={iconStyle} src={iconUrl} />
              </div>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

export default BotAttentionGrabberMsgBox;

import React, { useState, useEffect } from "react";
import "office-ui-fabric-react/dist/css/fabric.css";
import ReactWebChat, {
  createDirectLine,
  createCognitiveServicesSpeechServicesPonyfillFactory,
} from "botframework-webchat";

import { ConnectionStatus } from "botframework-directlinejs";
import { initializeIcons } from "@uifabric/icons";
// import { Icon } from "@fluentui/react/lib/Icon";
import { Resizable } from "re-resizable";
import Grid from "@material-ui/core/Grid";
import { GridDirection } from "@material-ui/core";
import { GridJustification } from "@material-ui/core";
import { Props } from "../../interfaces/MSBotInterface";
import { getProperties } from "../../utils/localApi";
// import { reactAI } from "react-appinsights";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { isMobile } from "react-device-detect";
import { hexToCSSFilter } from 'hex-to-css-filter';
import parse from 'html-react-parser';
import BotAttentionGrabberCurve from "./BotAttentionGrabberCurve";
import BotAttentionGrabberMsgBox from "./BotAttentionGrabberMsgBox";
import { TailSpin } from 'react-loader-spinner';
import useNavigator from "react-browser-navigator";
import * as botUser from 'react-device-detect';
import { UAParser } from 'ua-parser-js'; 

const mobile = require("is-mobile");

const axios = require("axios");
initializeIcons();

let parser = new UAParser();
var result = parser.getResult();

var agent = window.navigator.userAgent;
var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;






//generate cancel icon for usage in bot header
// const CancelIcon = () => (
//   <Icon iconName="Cancel" styles={{ root: { fontWeight: 800 } }} />
// );

//get custId from div attributes if it exists
var custId = "anon";
if (document.currentScript.getAttribute("nova-custId")) {
  custId = document.currentScript.getAttribute("nova-custId");
}



export const MSBot: React.FunctionComponent<Props> = (props) => {
  //initialize appInsights
  //initialize telemetry app insights and set static records
  // let appInsights = new ApplicationInsights({
  //   config: {
  //     instrumentationKey: props.appInsightIKey,
  //     extensions: [reactAI],
  //   },
  // });
  // appInsights.loadAppInsights();

  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
      config: {
          instrumentationKey: props.appInsightIKey,
          extensions: [reactPlugin],
      }
  });
  appInsights.loadAppInsights();

  //for Access Browser Navigator Properties
  let { getCurrentPosition } = useNavigator();
  let { language } = useNavigator();

  //-------- bot state code -------//
  //set bot size defaults depending on whether mobile version enabled or not
  var defaultBotSizeHeight = props.botHeight ?? window.innerHeight * 0.65;
  var defaultBotSizeWidth = props.botWidth ?? document.body.scrollWidth * 0.25;

  //set initial size if you're on a mobile device
  useEffect(() => {
    //detect if using a mobile device
    var isMobile = mobile();
    //add mobile version setting only on first page load
    if (isMobile) {
      var newBotHeight = window.innerHeight * 0.8;
      var newBotWidth = document.body.scrollWidth * 0.98;
      setBotHeight(newBotHeight);
      setBotWidth(newBotWidth);
      setMobileBotMinHeight(newBotHeight)
      setMobileBotMinWidth(newBotWidth)
    }
  }, [props.orientation]);

  //if embed version is enabled set bot width/height to inherit
  useEffect(() => {
    if (props.viewStyle == "embed") {
      setBotHeight(200);
      setBotWidth(200);
    }
  }, [props.viewStyle]);

  //if mobile version is enabled - make bot display on default
  var initFlexDisplay = "none";
  if (props.mobileVersion == true) {
    initFlexDisplay = "block";
  }

  //hooks for setting all the states
  const [iconClick, setIconClick] = useState<boolean>(props.autoPopUp); //for handling actions from bot icon click
  const [botDisplay, setBotDisplay] = useState<string>(initFlexDisplay); //for handling whether bot should be displayed or not
  const [botHeight, setBotHeight] = useState<any>(defaultBotSizeHeight); //for handling bot resize
  const [botWidth, setBotWidth] = useState<any>(defaultBotSizeWidth); //for handling bot resize
  const [botGridDirection, setBotGridDirection] = useState<GridDirection>("row-reverse"); //direction of elements of bot on page (desktop only)
  const [botGridJustify, setBotGridJustify] = useState<GridJustification>("flex-start"); //justify of elements on bot page (desktop only)
  const [flexDisplay, setFlexDisplay] = useState<string>(initFlexDisplay); //for handling display of transparent box for resizing the bot
  const [botLoading, setBotLoading] = useState<boolean>(false); //for determining whether bot direct line has been retrieved or not
  const [directLinev2, setDirectLinev2] = useState<any>(); //for direct line object
  const [properties, setProperties] = useState<any>(null); //for properties loaded in from botProperties json in public folder
  const [styleOptions, setStyleOptions] = useState<any>(null); //for direct line object style options
  const [botStyle, setBotStyle] = useState<any>({}); //for general styling of the bot
  const [botMsgExitStyle, setBotMsgExitStyle] = useState<any>({});
  const [botButtonStyle, setBotButtonStyle] = useState<any>({}); //style for regular primary buttons in bot
  const [botButtonCancelStyle, setBotButtonCancelStyle] = useState<any>({}); //style for regular primary buttons in bot
  const [botMsgStyle, setBotMsgStyle] = useState<any>({}); //for styling of the bot prompt message
  const [privacyStyle, setPrivacyStyle] = useState<any>({}); //for styling privacy warning message
  const [privacyButtonStyle, setPrivacyButtonStyle] = useState<any>({}); //for styling privacy buttons message
  const [botPromptMsg, setBotPromptMsg] = useState<any>("Click to open bot"); //for bot prompt message text
  const [resizeStyle, setResizeStyle] = useState<any>({}); //for the style of resize component of the bot
  const [cancelStyle, setCancelStyle] = useState<any>({}); //for the style of exit button of the bot
  const [footerStyle, setFooterStyle] = useState<any>({}); // for the style of footer of the bot
  const [botSubHeader, setBotSubHeader] = useState<any>({}); //for the style of the sub header of the bot
  const [botPonyfill, setBotPonyfill] = useState<any>(); //for the bot ponyfill object
  const [botMarkPath, setBotMarkPath] = useState<string>(null); //for the bot mark path url
  const [botClosePath, setBotClosePath] = useState<string>(null); //path for close button on desktop

  const [privacyStatus, setPrivacyStatus] = useState<boolean>(true); //for whether or not the privacy message should show
  const [privacyPolicy, setPrivacyPolicy] = useState<string>(null); //for privacy policy message
  const [botSound , setBotOpenSound]=useState<any>(null) //for BotOpen Sound


  //for mobile view problem
  const [mobileBotMinHeight,setMobileBotMinHeight]=useState<any>(defaultBotSizeHeight);

  const [mobileBotMinWidth,setMobileBotMinWidth]=useState<any>(defaultBotSizeWidth);

  //--- functions ---//
  //create axios instance for direct line secret API call
  const directLineAPI = axios.create({
    baseURL: "https://directline.botframework.com/v3/directline/tokens",
    timeout: 5000,
  });

  const speechAPI = axios.create({
    baseURL: "https://eastus2.api.cognitive.microsoft.com/sts/v1.0/issuetoken",
    timeout: 5000,
  });

  //for loading Spinner
  const loadingSpinner =()=>{
    if(props.loadingSpinnerEnabled === true ){
        return (
          <TailSpin
            height="70"
            width="70"
            color="#561f76"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="novaSpinner"
            visible={true}
          />
        );
    }else{
      return null
    }
  };

  //function For Bot Sound
  const play=()=> {
    var audio = new Audio(botSound);
    audio.play();
  }

  //uses axios instance to recieve token from direct line API with channel secret (change header url access controls to properties file basUrl)
  async function getDLToken(userId, name) {
    var botTokenObj = null;
    if (props.storageType == "local") {
      botTokenObj = JSON.parse(localStorage.getItem(`${custId}-botToken`));
    } else if (props.storageType == "session") {
      botTokenObj = JSON.parse(
        window.sessionStorage.getItem(`${custId}-botToken`)
      );
    }
    if (botTokenObj) {
      var botTokenStr = botTokenObj.value;
      //in case bot token is null
      if (botTokenStr) {
        if (botTokenStr == "not-set") {
          //code for reconnecting existing conversation
          const data = {
            user: {
              id: `dl_${userId}`,
              name: `${name}`,
            },
          };
          const res = await directLineAPI.post("/generate/", data, {
            headers: {
              Authorization: `Bearer ${props.secret}`,
              "Access-Control-Allow-Origin": window.location.origin,
            },
          });
          return res.data.token;
        } else {
          return botTokenStr;
        }
      }
    }
  }

  //function to refresh the bot's direct line token
  async function refreshDLToken(token) {
    if (token) {
      const res = await directLineAPI.post("/refresh/", null, { 
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": window.location.origin,
        },
      });
    }
  }

  //function for retrieving credentials for the bot speech api
  async function fetchCredentials(speechKey, speechRegion) {
    const tokenUrl =
      "https://" +
      speechRegion +
      ".api.cognitive.microsoft.com/sts/v1.0/issuetoken";
    const res = await fetch(tokenUrl, {
      method: "POST",
      headers: {
        "Ocp-Apim-Subscription-Key": speechKey,
      },
    });
    if (!res.ok) {
      // throw new Error("Failed to fetch authorization token and region.");
      console.warn("Failed to fetch authorization token and region.");
    }
    return { authorizationToken: await res.text(), region: speechRegion };
  }

  //function for getting a ponyfill object to use bot speech with
  async function getBotSpeechPonyfill(speechKey, speechRegion) {
    const response = await fetchCredentials(speechKey, speechRegion);
    const adapters = await createCognitiveServicesSpeechServicesPonyfillFactory(
      { credentials: response }
    );
    return adapters;
  }

  //function for securing and handling bot token
  function obtainBotToken() {
    var userName = props.name;
    getDLToken(props.userId, userName).then((tokenRes) => {
      var botConversationObj = null;
      if (props.storageType == "local") {
        botConversationObj = JSON.parse(
          localStorage.getItem(`${custId}-botConversationId`)
        );
      } else if (props.storageType == "session") {
        botConversationObj = JSON.parse(
          window.sessionStorage.getItem(`${custId}-botConversationId`)
        );
      }
      if (botConversationObj) {
        var botConversationIdStr = botConversationObj.value;
        if (botConversationIdStr == "not-set") {
          var tokenObj = {
            value: "not-set",
          };
          tokenObj.value = tokenRes;
          setBotToken(tokenObj);
          if (botToken) {
            if (props.storageType == "local") {
              localStorage.setItem(
                `${custId}-botToken`,
                JSON.stringify(botToken)
              );
            } else if (props.storageType == "session") {
              window.sessionStorage.setItem(
                `${custId}-botToken`,
                JSON.stringify(botToken)
              );
            }
          }
        }
      }
    });
  }

  //function to handle creation of directLine
  function handleDirectLine() {
    if (botToken.value == "not-set" || botToken.value == null) {
      //do nothing - leaving here for if console logs needed for debugging
    } else {
      //bot token has been generated, proceed in creating direct line (use existing conversationID if in localstorage)
      var botTokenStr = botToken.value;
      var botConvObj = null;
      if (props.storageType == "local") {
        botConvObj = JSON.parse(
          localStorage.getItem(`${custId}-botConversationId`)
        );
      } else if (props.storageType == "session") {
        botConvObj = JSON.parse(
          window.sessionStorage.getItem(`${custId}-botConversationId`)
        );
      }
      if (botConvObj) {
        var botConvId = botConvObj.value;
        if (botConvId != "not-set") {
          setBotConversationId(botConvObj);
          var streamUrlObj = null;
          if (props.storageType == "local") {
            streamUrlObj = JSON.parse(
              localStorage.getItem(`${custId}-streamUrl`)
            );
          } else if (props.storageType == "session") {
            streamUrlObj = JSON.parse(
              window.sessionStorage.getItem(`${custId}-streamUrl`)
            );
          }
          var streamUrlStr = streamUrlObj.value;
          if (streamUrl != "not-set") {
            setDirectLinev2(
              createDirectLine({
                token: botTokenStr,
                conversationId: botConvId,
                streamUrl: streamUrlStr,
              })
            );
          }
        } else {
          //bot conversation id/stream url doesn't exist yet - just set with token
          setDirectLinev2(createDirectLine({ token: botTokenStr }));
        }
      }
    }
  }

  //function to handle states that store in local storage
  function useStickyState(defaultValue, key) {
    if (props.storageType == "local") {
      const [value, setValue] = React.useState(() => {
        const stickyValue = window.localStorage.getItem(key);
        //set state to localStorage item or default value if null
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
      });

      React.useEffect(() => {
        //if state changes - update local storage item
        window.localStorage.setItem(key, JSON.stringify(value));
      }, [key, value]);

      return [value, setValue];
    } else if (props.storageType == "session") {
      const [value, setValue] = React.useState(() => {
        const stickyValue = window.sessionStorage.getItem(key);
        //set state to localStorage item or default value if null
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
      });

      React.useEffect(() => {
        //if state changes - update local storage item
        window.sessionStorage.setItem(key, JSON.stringify(value));
      }, [key, value]);

      return [value, setValue];
    }
  }

  //function for refreshing bot token related states
  function refreshBot() {
    var genericObj = {
      value: "not-set",
    };
    if (props.storageType == "local") {
      localStorage.removeItem(`${custId}-botToken`);
      localStorage.removeItem(`${custId}-botConversationId`);
      localStorage.removeItem(`${custId}-streamUrl`);
    } else if (props.storageType == "session") {
      window.sessionStorage.removeItem(`${custId}-botToken`);
      window.sessionStorage.removeItem(`${custId}-botConversationId`);
      window.sessionStorage.removeItem(`${custId}-streamUrl`);
    }
    setBotToken(genericObj);
    setBotConversationId(genericObj);
    setStreamUrl(genericObj);
    obtainBotToken();
  }
  
  //function for handling connections status of bot
  function handleBotStatus(directLineIn) {
    var novaBotUser = {
      BrowserName: botUser.browserName ?? '',
      BrowserVersion: botUser.browserVersion ?? '',
      CPUArchitect: result.cpu.architecture ?? '',
      DeviceModel: botUser.mobileModel ?? '',
      DeviceType: botUser.deviceType ?? '',
      DeviceVendor: botUser.mobileVendor ?? '',
      EngineName: botUser.engineName ?? '',
      EngineVersion: botUser.engineVersion ?? '',
      IsDesktop: botUser.isDesktop ?? false,
      IsMobile: botUser.isMobile ?? false,
      IsTablet: botUser.isTablet ?? false,
      IsAndroid: botUser.isAndroid ?? false,
      MobileModel:botUser.mobileModel ?? '',
      OSName: botUser.osName,
      OSVersion: botUser.osVersion,
      ScreenResolution: (window.screen.width * window.devicePixelRatio) + 'x' + (window.screen.height * window.devicePixelRatio),
      TimeZone: timeZone,
      UserAgent: agent,
      DeviceDetect: botUser.deviceDetect() ?? {},
      language:language ?? '',
      Location: {latitude:getCurrentPosition?.coords.latitude,longitude:getCurrentPosition?.coords.longitude} ?? {},
      UrlName: document.querySelector("meta[property='og:url']") ? document.querySelector("meta[property='og:url']").getAttribute("content") : "null",
      TitleName: document.querySelector("meta[property='og:title']") ?document.querySelector("meta[property='og:title']").getAttribute("content") : "null",
      SiteName :document.querySelector("meta[property='og:site_name']") ?document.querySelector("meta[property='og:site_name']").getAttribute("content") : "null",
      Description: document.querySelector("meta[property='og:description']") ? document.querySelector("meta[property='og:description']").getAttribute("content") : "null",
      clientName: props.clientName,
    };
    
    if (directLineIn) {
      if (directLineIn) {
        directLineIn.connectionStatus$.subscribe((connectionStatus) => {
          switch (connectionStatus) {
            //show current status of the direct line bot connection
            case ConnectionStatus.Uninitialized: // the status when the DirectLine object is first created/constructed
              // console.log("Bot Connection Uninitialized");
              //indicate that bot can start loading
              setBotLoading(true);
              break;
            case ConnectionStatus.Connecting: // currently trying to connect to the conversation
              // console.log("Bot Connection Connecting");
              break;
            case ConnectionStatus.Online: // successfully connected to the converstaion. Connection is healthy so far as we know.
              // console.log("Bot Connection Online");
              directLineIn
                .postActivity({
                  from: 
                  {
                    id: props.userId,
                  },
                  name: "requestWelcomeDialog",
                  type: "event",
                  value: "",
                  channelData : {
                    novaBotUser
                  },
                })
                .subscribe(function () {
                  //add any code you want to happen after function has occured here
                });

              // create event listener for triggering buttonAction event
              document
                .getElementById("novaBot")
                .addEventListener("novaButtonAction", function (event: any) {
                  //if bot hasn't sliden out, force this action
                  if (iconClick == false) {
                    setIconClick(true);
                  }
                  //use event's input message to post activity to bot
                  directLineIn
                    .WebChatActivity({
                      from: {
                        id: props.userId,
                      },
                      name: "novaButtonAction",
                      type: "event",
                      value: event.detail,
                    })
                    .subscribe(function () {
                      //add any code you want to happen after function has occured here
                    });
                });

              //set bot conversation Id and persist to local storage
              var botConvId = {
                value: directLineIn.conversationId,
              };
              setBotConversationId(botConvId);
              if (props.storageType == "local") {
                localStorage.setItem(
                  `${custId}-botConversationId`,
                  JSON.stringify(botConvId)
                );
              } else if (props.storageType == "session") {
                window.sessionStorage.setItem(
                  `${custId}-botConversationId`,
                  JSON.stringify(botConvId)
                );
              }

              //set stream url and persist to local storage
              var streamUrl = {
                value: directLineIn.streamUrl,
              };
              setStreamUrl(streamUrl);
              if (props.storageType == "local") {
                localStorage.setItem(
                  `${custId}-streamUrl`,
                  JSON.stringify(streamUrl)
                );
              } else if (props.storageType == "session") {
                window.sessionStorage.setItem(
                  `${custId}-streamUrl`,
                  JSON.stringify(streamUrl)
                );
              }

              break;
            case ConnectionStatus.ExpiredToken: // last operation errored out with an expired token. Your app should supply a new one.
              // console.log("Bot Token Expired");
              refreshBot();
              break;
            case ConnectionStatus.FailedToConnect: // the initial attempt to connect to the conversation failed. No recovery possible.
              // console.log("Bot Failed to Connect");
              refreshBot();
              break;
            case ConnectionStatus.Ended: // the bot ended the conversation
              // console.log("Bot Connection Ended");
              break;
          }
        });
      }
    } else {
      setBotLoading(false);
    }
  }
  //function for the toggle of the bot icon using iconClick state
  const toggleTrueFalse = () => {
    setIconClick(!iconClick);
  };


  const toggleTrueFalseNew = () => {
    setIconClick(!iconClick);
    // play();
  };
  //function for hiding bot message (when exit button is pressed)
  function closeMessage() {
    //set local/session storage for botMsgVisible
    var closeMsgVar = {
      value: false,
    };
    setBotMsgVisible(closeMsgVar);
  }

  function acceptPrivacy() {
    //set local/session storage for privacyPolicy to false
    var privacyStatusObj = {
      value: false
    }
    setPrivacyStatusVar(privacyStatusObj);
    setPrivacyStatus(false);
  }

  //function for converting array of fonts to font mapping
  function fontFamily(fonts) {
    return fonts.map((font) => `'${font}'`).join(", ");
  }

  //for handling bot resize
  const onResize = (event, { element, size, handle }) => {
    setBotWidth(size.width);
    setBotHeight(size.height);
  };

  //function for generating telemetry
  const handleTelemetry = (event) => {
    const {
      data,
      dimensions,
      duration,
      error,
      fatal,
      level,
      name,
      type,
    } = event;
    const fullName = `webchatui:${name}`;
    const telemetryInitializer = () => {
      // const { content: webChatUIVersion } =
      //   document.querySelector( 'head meta[name="botframework-webchat:ui:version"]' ) || {};

      appInsights.context.application.ver = "1.2";
      appInsights.context.session.acquisitionDate = Date.now();
      appInsights.context.session.id = botConversationId.value;
      appInsights.context.user.accountId = props.userId;
    };

    // console.group(`onTelemetry ("${type}")`);
    // console.log({ name, data, dimensions, duration, error });
    // console.groupEnd();

    if (type === "event") {
      const appInsightsData =
        typeof data === "number" || typeof data === "string"
          ? { [name]: data }
          : Object.entries(data || {}).reduce(
            (data, [key, value]) => ({ ...data, [`${name}:${key}`]: value }),
            {}
          );
      //initialize telemetry properties for event
      telemetryInitializer();
      appInsights.trackEvent({
        name: fullName,
        properties: {
          ...dimensions,
          ...appInsightsData,
          "webchat:level": level,
        },
      });
    } else if (type === "exception") {
      appInsights.trackException({ exception: error });
    } else if (type === "timingstart") {
      appInsights.startTrackEvent(fullName);
    } else if (type === "timingend") {
      appInsights.stopTrackEvent(fullName, { ...dimensions });
    }
  };

  ///--- logic hooks for bot ---//
  //generic object to store key and value
  var genericObj = {
    value: "not-set",
  };
  var trueObj = {
    value: true,
  };
  //sticky states
  var [botToken, setBotToken] = useStickyState(
    genericObj,
    `${custId}-botToken`
  ); //for bot token
  var [botConversationId, setBotConversationId] = useStickyState(
    genericObj,
    `${custId}-botConversationId`
  ); //for bot direct line conversation id
  var [streamUrl, setStreamUrl] = useStickyState(
    genericObj,
    `${custId}-streamUrl`
  ); //for bot direct line stream url
  var [botMsgVisible, setBotMsgVisible] = useStickyState(
    trueObj,
    `${custId}-botMsgVisible`
  );
  //for bot privacy policy
  var [privacyStatusVar, setPrivacyStatusVar] = useStickyState(
    trueObj,
    `${custId}-botPrivacyStatus`
  );


  //code for default actions once bot is loaded
  useEffect(() => {

    if (botLoading) {

      if (document.getElementById("novaBotMsg") && document.getElementById("novaBotMsgExit")) {
        //create event listener for when bot message is clicked
        document
          .getElementById("novaBotMsg")
          .addEventListener("click", function (event: any) {
            toggleTrueFalse();
            toggleTrueFalseNew();
            event.stopPropagation();
          });

        //create event listener for when bot message exit is clicked
        document
          .getElementById("novaBotMsgExit")
          .addEventListener("click", function (event: any) {
            closeMessage();
            event.stopPropagation();
          });
      }
    }
  }, [botLoading]);

  //code for refreshing bot token on a regular basis (in miliseconds)
  useEffect(() => {
    const timer = setInterval(() => {
      refreshDLToken(botToken.value);
    }, 180000);
    // clearing interval
    return () => clearInterval(timer);
  });

  //collect bot token
  useEffect(() => {
    obtainBotToken();
  }, [props.userId, props.name]);

  //create direct line and memorize
  useEffect(() => {
    getProperties()
      .then(function (result) {
        if (result.data && botToken.value != "not-set") {
          setProperties(result.data);
          const emptyObject={};
          if (properties != emptyObject) {
            var botCancelFilter = hexToCSSFilter(result.data.theme.themePrimary).filter;
            botCancelFilter = botCancelFilter.slice(0, -1); //remove semicolon from string
            var botStyleVar: { [k: string]: any } = {};
            var cancelButtonStyles: { [k: string]: any } = {};
            const closeButtonPlacement=props.closeButtonPlacement ?? "default";
            if (props.viewStyle == "desktop") {
              cancelButtonStyles = {
                height: 48,
                width: 48,
                filter: botCancelFilter,
                position: "absolute",
                top: -48,
                right: -5
              };
              botStyleVar = {
                height: "fit-content",
                width: botWidth,
                display: botDisplay,
                marginBottom: "15%",
                bottom: 180,
                backgroundColor: result.data.theme.themePrimary,
                borderRadius: 20,
                align: "center",
                boxShadow: "3px 25px 25px 3px rgba(0,0,0,0.75)",
                fontFamily: "NovaBotFont",
                scrollbarWidth: "thin"
              };
              //set positioning of bot based on botproperties
              var botMsgExitStyleVar: { [k: string]: any };
              if (result.data.generalSettings.ui.botPlacement == "bottom-right") {
                setBotGridDirection("row-reverse");
                setBotGridJustify("flex-start");
                if(closeButtonPlacement==="inHeader"){
                  cancelButtonStyles = {
                    height: 44,
                    width: 44,
                    position: "absolute",
                    top: 5,
                    right: 17,
                    cursor: "pointer",
                  };
                  botMsgExitStyleVar = {
                    position: "absolute",
                    top: 10,
                    left: -25,
                    width: 15,
                    height: 15,
                  };
                }else{
                  cancelButtonStyles = {
                    height: 48,
                    width: 48,
                    filter: botCancelFilter,
                    position: "absolute",
                    top: -48,
                    right: -5,
                    cursor: "pointer",
                  };
                  botMsgExitStyleVar = {
                    position: "absolute",
                    top: 10,
                    left: -25,
                    width: 15,
                    height: 15,
                  };
                } 
              }
              else if (result.data.generalSettings.ui.botPlacement == "bottom-left") {
                setBotGridDirection("row");
                setBotGridJustify("flex-start");
                cancelButtonStyles = {
                  height: 48,
                  width: 48,
                  filter: botCancelFilter,
                  position: "absolute",
                  top: -48,
                  left: 0
                };
                botMsgExitStyleVar = {
                  position: "absolute",
                  top: 10,
                  right: -25,
                  width: 15,
                  height: 15,
                };
              }
              else if (result.data.generalSettings.ui.botPlacement == "top-left") {
                setBotGridDirection("row");
                setBotGridJustify("flex-start");
                cancelButtonStyles = {
                  height: 48,
                  width: 48,
                  filter: botCancelFilter,
                  position: "absolute",
                  bottom: -95,
                  left: -5
                };
                botMsgExitStyleVar = {
                  position: "absolute",
                  top: 10,
                  right: -25,
                  width: 15,
                  height: 15,
                };
              }
              else if (result.data.generalSettings.ui.botPlacement == "top-right") {
                setBotGridDirection("row-reverse");
                setBotGridJustify("flex-start");
                cancelButtonStyles = {
                  height: 48,
                  width: 48,
                  filter: botCancelFilter,
                  position: "absolute",
                  bottom: -95,
                  right: -5
                };
                botMsgExitStyleVar = {
                  position: "absolute",
                  top: 10,
                  left: -25,
                  width: 15,
                  height: 15,
                };
              }
            } else if (props.viewStyle == "mobile") {
              cancelButtonStyles = {
                height: 48,
                width: 48,
                filter: botCancelFilter,
                position: "absolute",
                top: -48,
                right: -5
              };
              botStyleVar = {
                height: "100%",
                width: "100%",
                marginBottom: "15%",
                top: "0px",
                left: "0px",
                backgroundColor: result.data.theme.themePrimary,
                align: "center",
                display: "block",
                fontFamily: "NovaBotFont",
                scrollbarWidth: "thin"
              };
            }
            else {
              cancelButtonStyles = {
                height: 48,
                width: 48,
                filter: botCancelFilter,
                position: "absolute",
                top: -48,
                right: -5
              };
              botStyleVar = {
                height: "inherit",
                width: "inherit",
                marginBottom: "15%",
                top: "inherit",
                left: "inherit",
                backgroundColor: result.data.theme.themePrimary,
                align: "center",
                display: "block",
                fontFamily: "NovaBotFont",
                scrollbarWidth: "thin"
              };
            }

            setBotStyle(botStyleVar);

            //resize box style
            var resizeStyleVar = {
              display: flexDisplay,
              borderRadius: 20,
              marginBottom: "15%",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(0,0,0,0.1)",
              width: botWidth,
              height: 500,
            };

            setResizeStyle(resizeStyleVar);

            setBotSubHeader({
              textAlign: "center" as const,
              color: result.data.theme.white,
              fontFamily: "NovaBotFont",
              fontWeight: "normal",
              backgroundColor: result.data.theme.themeDark,
              padding: 2,
              clipPath: "ellipse(65% 40% at 52% 15%)",
              height: 45,
            });

            setCancelStyle(cancelButtonStyles);

            setFooterStyle({
              fontFamily: "NovaBotFont",
              fontWeight: "normal",
              textAlign: "right",
              padding: 10,
            });

            setBotMsgStyle({
              fontFamily: "NovaBotFont",
              fontWeight: "normal",
              borderRadius: 4,
              background: result.data.theme.white,
              color: result.data.theme.black,
              boxShadow: "0px 7px 6px 1px rgb(0 0 0 / 16%)",
              maxHeight: props.iconSize,
              padding: 5,
              position: "relative",
            });
            setBotMsgExitStyle(botMsgExitStyleVar);
            const botStyledData=result.data.generalSettings.botStyles.botButtonStyle;
            var botButtonStyleVar: { [k: string]: any } = {};
            botButtonStyleVar = {
              backgroundColor: result.data.theme.themePrimary,
              color: result.data.theme.white,
              borderColor: result.data.theme.themePrimary,
              borderWidth: botStyledData.borderWidth ?? 2,
              borderRadius: botStyledData.borderRadius ?? 5,
              borderStyle:botStyledData.borderStyle ?? "solid",
              padding: botStyledData.padding ?? "5px 10px 5px 10px",
              alignItems: botStyledData.alignItems ?? "center",
              width: botStyledData.width ?? "100%",
              cursor: botStyledData.cursor ?? "pointer",
            }
            setBotButtonStyle(botButtonStyleVar);

            var botButtonCancelStyleVar: { [k: string]: any } = {};
            botButtonCancelStyleVar = {
              backgroundColor: result.data.theme.white,
              color: result.data.theme.themePrimary,
              borderColor: result.data.theme.themePrimary,
              borderWidth: botStyledData.borderWidth ?? 2,
              borderRadius: botStyledData.borderRadius ?? 5,
              borderStyle:botStyledData.borderStyle ?? "solid",
              padding: botStyledData.padding ?? "5px 10px 5px 10px",
              alignItems: botStyledData.alignItems ?? "center",
              width: botStyledData.width ?? "100%",
              cursor: botStyledData.cursor ?? "pointer",
            }
            setBotButtonCancelStyle(botButtonCancelStyleVar);

            var privacyStyleVar: { [k: string]: any } = {};
            var privacyPadding = 10;
            if (props.viewStyle == "mobile") {
              privacyStyleVar = {
                width: "100vw",
                height: "80vh",
                textAlign: "center",
                padding: privacyPadding,
                "-webkit-box-sizing": "border-box",
                "-moz-box-sizing": "border-box",
                "box-sizing": "border-box",
                overflow: "auto"
              };
            } else if (props.viewStyle == "embed") {
              privacyStyleVar = {
                width: "inherit",
                height: "inherit",
                top: "inherit",
                bottom: "inherit",
                right: "inherit",
                left: "inherit",
                textAlign: "center",
                padding: privacyPadding,
                "-webkit-box-sizing": "border-box",
                "-moz-box-sizing": "border-box",
                "box-sizing": "border-box",
                overflow: "auto"
              };
            } else {
              privacyStyleVar = {
                width: botWidth,
                height: botHeight - 125,
                textAlign: "center",
                padding: privacyPadding,
                "-webkit-box-sizing": "border-box",
                "-moz-box-sizing": "border-box",
                "box-sizing": "border-box",
                overflow: "auto"
              };
            }
            privacyStyleVar.backgroundColor = result.data.theme.white;
            setPrivacyStyle(privacyStyleVar);

            var privacyButtonStyleVar: { [k: string]: any } = {};
            if (props.viewStyle == "mobile") {
              privacyButtonStyleVar = {
                backgroundColor: result.data.theme.white,
                width: "100vw",
                alignItems: "center"
              }
            }
            else if (props.viewStyle == "embed") {
              privacyButtonStyleVar = {
                backgroundColor: result.data.theme.white,
                width: "inherit",
                alignItems: "center"
              }
            } else {
              privacyButtonStyleVar = {
                backgroundColor: result.data.theme.white,
                width: botWidth,
                alignItems: "center"
              }
            }

            setPrivacyButtonStyle(privacyButtonStyleVar);

            /*handle logic for whether privacy status message shows or not
            and set privacy policy message
            */
            if (result.data.generalSettings.privacyPolicy.privacyPolicyEnabled) {
              if (privacyStatusVar.value == true) {
                setPrivacyStatus(true);
              } else {
                setPrivacyStatus(false);
              }
            } else {
              setPrivacyStatus(false);
            }
            setPrivacyPolicy(result.data.generalSettings.privacyPolicy.privacyPolicyText);


            //inject bot and user icon props into style options
            var styleOptionObj = result.data.styleOptions;
            // styleOptionObj.userAvatarImage = props.userIcon;
            styleOptionObj.botAvatarImage = props.msgIconUrl;
            setStyleOptions(styleOptionObj);

            //set path for bot mark and Sound 
            if (custId != "anon") {
              setBotMarkPath(
                result.data.generalSettings.rootSite +
                "/" +
                custId +
                "/botMark.png"
              );
              setBotOpenSound(
                result.data.generalSettings.rootSite +
                "/" +
                custId +
                "/botsound.wav"
              );
            } else {
              setBotMarkPath(
                result.data.generalSettings.rootSite + "/botMark.png"
              );
              setBotOpenSound(
                result.data.generalSettings.rootSite + "/botsound.wav"
              );
            }

            //set path for close button
            setBotClosePath(result.data.generalSettings.rootSite + "/exit-button.svg");


            //set bot prompt message
            setBotPromptMsg(result.data.generalSettings.botAttentionGrabberMsgBoxConfig.botPromptMsg);
            //only show bot prompt if showBotPrompt set to true
            if (!result.data.generalSettings.attentionGrabber.showEnabled) {
              setBotMsgVisible(false);
            }

            //use fetchCredentials function to retrieve credentials for speech adapter and assign to bot
            getBotSpeechPonyfill(
              result.data.generalSettings.speechKey,
              result.data.generalSettings.speechRegion
            ).then((res) => {
              setBotPonyfill(() => res);
            });
          }
        }
      })
      .then(function () {
        handleDirectLine();
      });
  }, [botToken, botWidth, botHeight]);

  //handler for managing direct line state
  useEffect(() => {
    handleBotStatus(directLinev2);
  }, [directLinev2]);

  //--- css style objects  ----
  //styling for icon
  var iconStyle: { [k: string]: any } = {};
  iconStyle = {
    height: props.iconSize,
    width: props.iconSize - 10,
    borderRadius: 90,
    display: "block"
  };
  if (isMobile) {
    iconStyle.height = props.iconSize * 2;
    iconStyle.width = props.iconSize * 2 - 20;
  }
  if (!(!botMsgVisible.value && botMsgVisibleVar == "none")) {
    iconStyle.bottom = props.iconSize;
    iconStyle.right = props.iconSize;
  }

  //for general sizing of bot
  var webChatStyle: { [k: string]: any } = {};
  if (props.viewStyle == "mobile") {
    webChatStyle = {
      width: "100vw",
      height: "80vh",
    };
  } else if (props.viewStyle == "embed") {
    webChatStyle = {
      width: "inherit",
      height: "inherit",
      top: "inherit",
      bottom: "inherit",
      right: "inherit",
      left: "inherit",
    };
  } else {
    webChatStyle = {
      width: botWidth,
      height: botHeight - 100,
    };
  }

  //for loading gif
  var loadingGifStyle = {
    bottom: 20,
  };

  //styling for mark on bot header
  const markStyle = {
    width: "auto",
    height: 50,
    marginLeft:"15px",
    paddingLeft: botWidth * 0.04,
  };


  


  const getBotAttentionGrabber = () => {
    if(properties.generalSettings.attentionGrabber.botAttentionGrabberType === 'curve') {
      if(iconClick) return;
      if(properties.generalSettings.botAttentionGrabberCurveConfig.hideAttentionGrabber) return;
      return (
          <BotAttentionGrabberCurve
            imgSrc={props.iconUrl}
            imgAlt={"icon"}
            curveText={properties.generalSettings.botAttentionGrabberCurveConfig.title}
            textCurveDirection={properties.generalSettings.botAttentionGrabberCurveConfig.titleOrientation}
            startTextOffset={properties.generalSettings.botAttentionGrabberCurveConfig.startTextOffset}
            onClickLogo={toggleTrueFalseNew}
            custId={custId}
            curveTextEnabled={properties.generalSettings.attentionGrabber.showEnabled}
          />
      );
    }
    return (
      <BotAttentionGrabberMsgBox 
        botGridDirection={botGridDirection}
        botGridJustify={botGridJustify}
        botMsgVisibleVar={botMsgVisibleVar}
        iconStyle={iconStyle}
        botMsgVisible={botMsgVisible}
        botPromptMsg={botPromptMsg}
        botMsgStyle={botMsgStyle}
        iconUrl={props.iconUrl}
        botMsgExitStyle={botMsgExitStyle}
        onClick={toggleTrueFalseNew}
        custId={custId}
      />
    );
  }

  //for chat with agent
  const chatWithAgent =()=>{
    if(props.chatWithAgent === true ){
      function handleLiveChatClick() {
        directLinev2.postActivity({
            from: 
            {
              id: props.userId,
            },
            type: "message",
            text:"LiveChat"
          }).subscribe(function () {
          //add any code you want to happen after function has occured here
        });
        // directLinev2.postActivity({ from: { id: props.userId, }, type: 'message', text: 'livechat' }).subscribe( id => console.log(`Posted activity, assigned ID ${id}`), error => console.log(`Error posting activity ${error}`));
      }
      return (
          <div className="novaLiveAgent" onClick={handleLiveChatClick} style={{display: "flex",alignItems: "center",gap: "3px",cursor:"pointer"}}>
              <svg width="20" height="20" style={{marginLeft: "1rem"}} className="novaLiveChatSvg" viewBox="0 0 381 381" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Chat With Agent</title>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 190.5C0 85.4583 85.4583 0 190.5 0C295.542 0 381 85.4583 381 190.5V269.424C381 284.109 371.317 296.732 357.565 302.075C349.843 305.076 343.054 311.582 341.437 319.707C334.489 354.617 303.624 381 266.7 381H167.4C159.116 381 152.4 374.284 152.4 366V338.85C152.4 330.566 159.116 323.85 167.4 323.85H219.075C224.336 323.85 228.6 328.114 228.6 333.375C228.6 338.635 232.864 342.9 238.125 342.9H266.7C287.712 342.9 304.8 325.812 304.8 304.8V187.776C304.8 182.723 306.807 177.878 310.38 174.305C313.952 170.733 318.798 168.726 323.85 168.726C332.977 168.726 340.035 160.603 337.717 151.775C320.564 86.4592 261.134 38.1 190.5 38.1C119.866 38.1 60.4361 86.4592 43.2834 151.775C40.9652 160.603 48.0231 168.726 57.15 168.726C62.2024 168.726 67.0478 170.733 70.6204 174.305C74.1929 177.878 76.2 182.723 76.2 187.776V285.75C76.2 290.802 74.1929 295.648 70.6204 299.22C67.0478 302.793 62.2024 304.8 57.15 304.8H38.1C17.0878 304.8 0 288.931 0 269.424V190.5ZM275 179C275 213.276 254.47 242.758 225.036 255.818C212.689 262.997 196.45 273.999 189.042 287.086C177.685 270.225 165.507 261.56 153.714 254.292C126.03 240.556 107 212 107 179C107 132.608 144.608 95 191 95C237.392 95 275 132.608 275 179ZM157 202C163.627 202 169 196.627 169 190C169 183.373 163.627 178 157 178C150.373 178 145 183.373 145 190C145 196.627 150.373 202 157 202ZM202 190C202 196.627 196.627 202 190 202C183.373 202 178 196.627 178 190C178 183.373 183.373 178 190 178C196.627 178 202 183.373 202 190ZM224 202C230.627 202 236 196.627 236 190C236 183.373 230.627 178 224 178C217.373 178 212 183.373 212 190C212 196.627 217.373 202 224 202Z" fill="#621f70"/>
              </svg>
              <span className="novaLiveChatText" style={{color: "#3d003b",fontWeight: "bold",fontSize: "12px"}}>Live Chat</span>
          </div>
      )
    }else{
      return(
        null
      )
    }
  };


  //css effect functions
  //effect for icon and bot message appear/disappear
  var botMsgVisibleVar = "block";
  if (props.mobileVersion == false) {
    if (!iconClick) {
      //show icon
      iconStyle.display = "block";
      botStyle.display = "block";
      if (botMsgVisible.value) {
        botMsgVisibleVar = "block";
      } else {
        botMsgVisibleVar = "none";
      }

      resizeStyle.display = "none";
    } else {
      //hide icon
      iconStyle.display = "none";
      botStyle.display = "block";
      botMsgVisibleVar = "none";
      resizeStyle.display = "block";
    }
  }

  //for controlling height of privacy grid in embed
  var inheritStyle: { [k: string]: any } = {};

  if (props.viewStyle == "embed") {
    inheritStyle = {
      height: "inherit",
      width: "inherit"
    }
  }
  //React Components to Return from Functional Component
  return botLoading && properties ? (
    props.mobileVersion == false ? (
      <div className="novaBotRoot">
        <Resizable
          maxWidth={window.innerWidth - window.innerWidth * 0.58}
          maxHeight={window.innerHeight - window.innerHeight * 0.18}
          minWidth= {mobileBotMinWidth}
          minHeight= {mobileBotMinHeight}
          style={resizeStyle}
          size={{ width: botWidth, height: botHeight }}
          onResizeStop={(e, direction, ref, d) => {
            setBotWidth(botWidth + d.width);
            setBotHeight(botHeight + d.height);
          }}
          className="novaBotResizable"
        >
          <div className="novaBotWrapper" id="novaBotWebChat" style={botStyle}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="novaBotHeader"
            >
              <Grid item xs={2}>
                <div className="novaBotMark">
                  <img
                    className="novaBotMarkImg"
                    src={botMarkPath}
                    alt="bot mark"
                    style={markStyle}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: "center",
                  color: properties.theme.white,
                  fontFamily: "NovaBotFont",
                  fontWeight: "bold",
                  padding: 2,
                  marginLeft: -2,
                }}
                className="novaBotHeaderText"
              >
                {props.botName}
              </Grid>
              <Grid
                item
                xs={2}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                className="novaBotHeaderCancelGrid1"
              >
                <Grid
                  item
                  xs={4}
                  className="novaBotCancelGrid2"
                >
                  <img
                    src={botClosePath}
                    alt="close image"
                    style={cancelStyle}
                    onClick={toggleTrueFalse}
                    className="novaBotCancelDiv"
                  />
                </Grid>
                <Grid item xs={8} />
              </Grid>
            </Grid>
            {privacyStatus ? (
              <div>
                <div
                  className="novaBotSubHeader"
                  style={{ backgroundColor: properties.theme.white,display: props.botSubHeader }}
                >
                  <div className="novaBotSubHeaderText" style={botSubHeader}>
                    Privacy Policy
                  </div>
                </div>
                <Grid
                  container
                  direction="row-reverse"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={0}
                  className="novaPrivacyGrid"
                >
                  <Grid item>
                    <div className="novaPrivacy1" style={privacyStyle}>
                      {parse(privacyPolicy)}
                    </div>
                  </Grid>
                  <div className="novaPrivacy2" style={privacyButtonStyle}>
                    <div className="novaPrivacyButtons">
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className="novaPrivacyGrid2"
                        spacing={6}
                      >
                        <Grid item>
                          <button className="novaPrivacyButtonAccept" onClick={acceptPrivacy} style={botButtonStyle}>{props.acceptPrivacyButtonName}</button>
                        </Grid>
                        <Grid item>
                          <button className="novaPrivacyButtonDecline" onClick={toggleTrueFalse} style={botButtonCancelStyle}>{props.rejectPrivacyButtonName}</button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div
                    className="novaBotFooter1"
                    style={{
                      width: botWidth,
                      borderBottomLeftRadius: 20,
                      borderBottomRightRadius: 20,
                      backgroundColor: "white",
                    }}
                  >
                    <div className="novaBotFooter2" style={footerStyle}>
                      <style
                        dangerouslySetInnerHTML={{
                          __html: `
                            .novaBotFooter{
                              a{
                              color:${properties.theme.themePrimary}
                            }
                          }
                          `,
                        }}
                      ></style>
                      {properties.generalSettings.ui.footerText}
                      <a
                        className="novaBotFooterLink"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={properties.generalSettings.ui.footerLink}
                      >
                        {" " + properties.generalSettings.ui.footerCompany}
                      </a>
                    </div>
                  </div>
                </Grid>
              </div>
            ) : (
              <div>
                <div>
                  <div
                    className="novaBotSubHeader"
                    style={{ backgroundColor: properties.theme.white,display:props.botSubHeader }}
                  >
                    <div className="novaBotSubHeaderText" style={botSubHeader}>
                      {props.welcomeMessage}
                    </div>
                  </div>
                  <Grid
                    container
                    direction="row-reverse"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}
                    className="novaBotWebChatGrid"
                  >
                    <div className="novaWebChat1" style={webChatStyle}>
                      <ReactWebChat
                        adaptiveCardsHostConfig={properties.adaptiveCardsHostConfig}
                        directLine={directLinev2}
                        userID={`dl_${props.userId}`}
                        styleOptions={styleOptions}
                        onTelemetry={handleTelemetry}
                        webSpeechPonyfillFactory={botPonyfill}
                        className="novaWebChat2"
                      />
                      <div
                        className="novaBotFooter1"
                        style={{
                          width: botWidth,
                          borderBottomLeftRadius: 20,
                          borderBottomRightRadius: 20,
                          backgroundColor: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <div>
                          {chatWithAgent()}
                        </div>
                        <div className="novaBotFooter2" style={footerStyle}>  
                          <style
                            dangerouslySetInnerHTML={{
                              __html: `
                            .novaBotFooter{
                              a{
                              color:${properties.theme.themePrimary}
                            }
                          }
                          `,
                            }}
                          ></style>
                          {properties.generalSettings.ui.footerText}
                          <a
                            className="novaBotFooterLink"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={properties.generalSettings.ui.footerLink}
                          >
                            {" " + properties.generalSettings.ui.footerCompany}
                          </a>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>)}
          </div>
        </Resizable>
        <div className="novaBotClosed">
          {getBotAttentionGrabber()}
        </div>
      </div >
    ) : (
      <div id="novaBotWebChat" className="novaBotWrapper" style={botStyle}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ backgroundColor: properties.theme.themePrimary }}
          className="novaBotHeader"
        >
          <Grid item xs={2}>
            <div className="novaBotMark">
              <img
                className="novaBotMarkImg"
                src={botMarkPath}
                alt="bot mark"
                style={markStyle}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: "center",
              color: properties.theme.white,
              fontFamily: "NovaBotFont",
              fontWeight: "normal",
              padding: 2,
              marginLeft: -2,
            }}
            className="novaBotHeaderText"
          >
            {props.botName}
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            className="novaBotHeaderCancelGrid1"
          >
            <Grid item xs={8} className="novaBotHeaderCancelGrid2" />
          </Grid>
        </Grid>
        {privacyStatus ? (
          <div style={inheritStyle}>
            <div
              className="novaBotSubHeader"
              style={{ backgroundColor: properties.theme.white,display:props.botSubHeader }}
            >
              <div className="novaBotSubHeaderText" style={botSubHeader}>
                Privacy Policy
              </div>
            </div>
            <div className="novaWebChat1" style={webChatStyle}>
              <Grid
                container
                direction="row-reverse"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
                className="novaPrivacyGrid"
                style={inheritStyle}
              >
                <Grid item style={inheritStyle}>
                  <div className="novaPrivacy1" style={privacyStyle}>
                    {parse(privacyPolicy)}
                  </div>
                </Grid>
                <div className="novaPrivacy2" style={privacyButtonStyle}>
                  <div className="novaPrivacyButtons" style={{ width: "inherit" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                      className="novaPrivacyGrid2"
                      spacing={6}
                    >
                      <Grid item>
                        <button className="novaPrivacyButtonAccept" onClick={acceptPrivacy} style={botButtonStyle}>{props.acceptPrivacyButtonName}</button>
                      </Grid>
                      <Grid item>
                        <button className="novaPrivacyButtonDecline" onClick={toggleTrueFalse} style={botButtonCancelStyle}>{props.rejectPrivacyButtonName}</button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div
                  className="novaBotFooter1"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                > 
                  <div className="novaBotFooter2" style={footerStyle}>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
                            ."novaBotfooter2"{
                              a{
                              color:${properties.theme.themePrimary}
                            }
                          }
                          `,
                      }}
                    ></style>
                    {properties.generalSettings.ui.footerText}
                    <a
                      className="novaBotFooterLink"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={properties.generalSettings.ui.footerLink}
                    >
                      {" " + properties.generalSettings.ui.footerCompany}
                    </a>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="novaBotSubHeader"
              style={{ backgroundColor: properties.theme.white,display:props.botSubHeader }}
            >
              <div className="novaBotSubHeaderText" style={botSubHeader}>
                {props.welcomeMessage}
              </div>
            </div>
            <div className="novaWebChat1" style={webChatStyle}>
              <ReactWebChat
                adaptiveCardsHostConfig={properties.adaptiveCardsHostConfig}
                directLine={directLinev2}
                userID={`dl_${props.userId}`}
                styleOptions={styleOptions}
                webSpeechPonyfillFactory={botPonyfill}
                onTelemetry={handleTelemetry}
                className="novaWebChat2"
              />
            </div>
            <div
              className="novaBotFooter1"
              style={{
                width: "100%",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            > 
              <div>
                {chatWithAgent()}
              </div>
              <div className="novaBotFooter2" style={footerStyle}>
                <style
                  dangerouslySetInnerHTML={{
                    __html: `
                            ."novaBotfooter2"{
                              a{
                              color:${properties.theme.themePrimary}
                            }
                          }
                          `,
                  }}
                ></style>
                {properties.generalSettings.ui.footerText}
                <a
                  className="novaBotFooterLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={properties.generalSettings.ui.footerLink}
                >
                  {" " + properties.generalSettings.ui.footerCompany}
                </a>
              </div>
            </div>
          </div>
        )
        }
      </div>
    )
  ) : (
    //when component is loading -
    <div style={loadingGifStyle} className="loading-spinner">
      {loadingSpinner()}
    </div>
  );
};

import { useState, useEffect } from 'react';

function getWindowOrientation() {
 
  var orientation = null;
  if(window.innerHeight < window.innerWidth){
    orientation = 'landscape';
  }else{
    orientation = 'portrait';
  }
  return orientation;
}

export default function useWindowOrientation() {
  const [windowOrientation, setWindowOrientation] = useState(getWindowOrientation());

  useEffect(() => {
    function handleResize() {
      setWindowOrientation(getWindowOrientation());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowOrientation;
}
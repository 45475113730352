import React, { useState, useEffect } from "react";
import { MSBot } from "./components/common/MSBot";
import { getAuth, getUserInitials, createUUID } from "./utils/authProvider";
import { getProperties } from "./utils/localApi";
import useWindowOrientation from "./utils/useWindowDimensions";

//retrieve userId and userName from bot attributes
const divUserName = document.currentScript.getAttribute("nova-userName");
const divUserId = document.currentScript.getAttribute("nova-userId");

var custId = "anon";
if (document.currentScript.getAttribute("nova-custId")) {
  custId = document.currentScript.getAttribute("nova-custId");
}

export const App: React.FunctionComponent<{}> = () => {
  //states and their functions
  const [userDetails, setUserDetails] = useState<any>(null);
  const [propertyState, setPropertyState] = useState<any>(null);
  const [userPrincipalName, setUserPrincipalName] = useState<string>(null);
  const [givenName, setGivenName] = useState<string>(null);
  const [userIcon, setUserIcon] = useState<string>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [welcomeMsg, setWelcomeMsg] = useState<string>("");
  const [botSecret, setBotSecret] = useState<string>("");
  const [botIcon, setBotIcon] = useState<string>("");
  const [botMsgIcon, setBotMsgIcon] = useState<string>("");
  const [botName, setBotName] = useState<string>("");
  const [mobileVersion, setMobileVersion] = useState<boolean>(false);
  const [viewStyle, setViewStyle] = useState<string>(null);
  const [storageType, setStorageType] = useState<string>("local");
  const [appInsightIKey, setAppInsightIKey] = useState<string>("");
  const [footerStyle, setFooterStyle] = useState<any>(null);
  const [autoPopUp, setAutoPopUp] = useState<boolean>(false);

  const orientation = useWindowOrientation();

  //use effect to assign data to states asynchronously - if script tag has userId, do not use graph client
  useEffect(() => {
    getProperties().then((res) => {
      var properties = res.data;
      setPropertyState(properties);
      //if AD is enabgled, then get username, user photo and userId from MS Graph API
      if (properties.generalSettings.adEnabled) {
        getAuth().then((resAuth) => {
          if (divUserId == null || divUserId == "") {
            resAuth.getUserDetails().then((resDetail) => {
              setUserDetails(resDetail);
              setUserPrincipalName(resDetail.userPrincipalName);
            });
          }
        });
      } else {
        //AD not enabled either generate user id and use default username or get userId and username from div attributes
        var userId = null;
        //if AD not enabled and no userId provided and
        if (!divUserId) {
          //variables to store conversation Id and user ID from session/local storage
          var botConversationObj = null;
          var storageUserIdObj = null;
          //populate variables depending on property state
          if (properties.generalSettings.storageType == "session") {
            botConversationObj = JSON.parse(
              window.sessionStorage.getItem(`${custId}-botConversationId`)
            );
            storageUserIdObj = JSON.parse(
              window.sessionStorage.getItem(`${custId}-userid`)
            );
          } else if (properties.generalSettings.storageType == "local") {
            botConversationObj = JSON.parse(
              localStorage.getItem(`${custId}-botConversationId`)
            );
            storageUserIdObj = JSON.parse(
              localStorage.getItem(`${custId}-userid`)
            );
          }

          //if user Id already exists, use that - else create new user
          if (storageUserIdObj) {
            userId = storageUserIdObj.value;
          } else if (
            botConversationObj == null ||
            botConversationObj.value == "not-set"
          ) {
            userId = createUUID();
            var userIdObj = {
              value: userId,
            };
            if(properties.generalSettings.storageType == "session"){
              window.sessionStorage.setItem(`${custId}-userid`, JSON.stringify(userIdObj));
            }
            else if(properties.generalSettings.storageType == "local"){
              localStorage.setItem(`${custId}-userid`, JSON.stringify(userIdObj));
            }
            
          }
        } else {
          userId = divUserId;
        }
        setUserPrincipalName(userId);
      }
    });
  }, []);

  //set givenName post populating userPrincipalName
  useEffect(() => {
    if (userPrincipalName) {
      if (propertyState.generalSettings.adEnabled) {
        //set given name from
        setGivenName(userDetails.givenName);
      } else {
        //default username to guest
        var userName = "Guest";
        //set given name from properties
        if (divUserName) {
          userName = divUserName;
        } else if (propertyState.generalSettings.guestUserName) {
          userName = propertyState.generalSettings.guestUserName;
        }
        setGivenName(userName);
      }
    }
  }, [userPrincipalName]);

  //set user photo post populating userPrincipalName and indicate that bot can be loaded
  useEffect(() => {
    if (givenName) {
      if (propertyState.generalSettings.adEnabled) {
        //if AD is enabled set userIcon from graph API
        getAuth().then((resAuth) => {
          resAuth
            .getUserGraphPhoto(userPrincipalName, givenName)
            .then((resPhoto) => {
              setUserIcon(resPhoto);
            });
        });
      } else {
        //if AD is not enabled, return intitals image from givenName as userIcon
        getUserInitials(givenName).then((res) => {
          setUserIcon(res);
        });
      }
    }
  }, [givenName]);

  //set remainder of properties needed for bot  and indicate that bot is ready to open - post userIcon set
  useEffect(() => {
    if (userIcon) {
      //style objects
      var footerStyleObj: any = {};
      if (propertyState.generalSettings.ui.viewStyle == "mobile") {
        footerStyleObj = {
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10000,
        };
      } else if (propertyState.generalSettings.ui.viewStyle == "embed") {
        footerStyleObj = {
          bottom: "inherit",
          right: "inherit",
          left: "inherit",
          top: "inherit",
          width: "inherit",
          height: "inherit",
          zIndex: 10000,
        };
      } else {
        if(propertyState.generalSettings.ui.botPlacement=="bottom-right"){
          footerStyleObj = {
            position: "fixed",
            bottom: 5,
            right: 5,
            zIndex: 10000,
          };
        }
        else if(propertyState.generalSettings.ui.botPlacement=="bottom-left"){
          footerStyleObj = {
            position: "fixed",
            bottom: 5,
            left:5,
            zIndex: 10000,
          };
        }
        else if(propertyState.generalSettings.ui.botPlacement=="top-left"){
          footerStyleObj = {
            position: "fixed",
            top: 5, 
            left:5,
            zIndex: 10000,
          };
        }
        else if(propertyState.generalSettings.ui.botPlacement=="top-right"){
          footerStyleObj = {
            position: "fixed",
            top: 5, 
            right:5,
            zIndex: 10000,
          };
        }
        
      }
      setFooterStyle(footerStyleObj);
      //set welcome message. bot secret, bot name, mobile version and storage type from properties file
      setWelcomeMsg(propertyState.generalSettings.ui.botWelcome);
      setBotSecret(propertyState.generalSettings.botSecret);
      setBotName(propertyState.generalSettings.ui.botName);
      if (
        propertyState.generalSettings.ui.viewStyle == "embed" ||
        propertyState.generalSettings.ui.viewStyle == "mobile"
      ) {
        setMobileVersion(true);
      } else {
        setMobileVersion(false);
      }

      setStorageType(propertyState.generalSettings.storageType);
      setBotIcon(propertyState.generalSettings.ui.botIcon);
      setBotMsgIcon(propertyState.generalSettings.ui.botMsgIcon);
      setAppInsightIKey(propertyState.generalSettings.appInsightIKey);
      setViewStyle(propertyState.generalSettings.ui.viewStyle);
      setAutoPopUp(propertyState.generalSettings.ui.autoPopUp);
      //set loaded as true
      setLoaded(true);
    }
  }, [userIcon]);
  
  return loaded ? (
    <div style={footerStyle}>
      <MSBot
        iconUrl={botIcon}
        msgIconUrl={botMsgIcon}
        userId={userPrincipalName}
        secret={botSecret}
        iconSize={80}
        name={givenName}
        userIcon={userIcon}
        botName={botName}
        welcomeMessage={welcomeMsg}
        mobileVersion={mobileVersion}
        storageType={storageType}
        appInsightIKey={appInsightIKey}
        orientation={orientation}
        viewStyle={viewStyle}
        autoPopUp={autoPopUp}
        botSubHeader={propertyState.generalSettings.ui.botSubHeader}
        botWidth={propertyState.generalSettings.ui.botWidth}
        botHeight={propertyState.generalSettings.ui.botHeight}
        botAttentionGrabberType={propertyState.generalSettings.attentionGrabber.botAttentionGrabberType}
        acceptPrivacyButtonName={propertyState.generalSettings.privacyPolicy.acceptPrivacyButtonName}
        rejectPrivacyButtonName={propertyState.generalSettings.privacyPolicy.rejectPrivacyButtonName}
        closeButtonPlacement={propertyState.generalSettings.ui.closeButtonPlacement}
        loadingSpinnerEnabled={propertyState.generalSettings.ui.loadingSpinnerEnabled}
        rootSite={propertyState.generalSettings.rootSite}
        chatWithAgent={propertyState.generalSettings.ui.chatWithAgent ?? false}
        clientName={propertyState.generalSettings.clientName ?? "" }
        />
    </div>
  ) : (
    <div></div>
  );
};
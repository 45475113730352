import React, { useState,useEffect } from 'react';
import TextCurve from '../TextCurve';
import { getProperties } from "../../../utils/localApi";
import './index.css';

interface IBotAttentionGrabberCurve {
  imgSrc: string;
  imgAlt: string;
  imgClass?: string;
  curveText: string;
  imgHeight?: string;
  imgWidth?: string;
  imgProps?: any;

  startTextOffset?: number;
  textCurveDirection?: 'clockwise' | 'counterClockwise';
  onClickLogo?: () => void;
  onCloseText?: () => void;
  custId?: any;
  curveTextEnabled?: boolean;
}

BotAttentionGrabberCurve.defaultProps = {
  imgHeight: '80px',
  imgWidth: '80px',
  textCurveDirection: 'clockwise'
};

function BotAttentionGrabberCurve(props: IBotAttentionGrabberCurve) {
  const {
    imgSrc,
    imgAlt,
    imgHeight,
    imgWidth,
    imgProps,
    curveText,
    imgClass,
    textCurveDirection,
    onCloseText,
    startTextOffset,
    custId,
    curveTextEnabled,
  } = props;

  const [closeCurveText, setCloseCurveText] = useState(false);
  const [closeButtonIcon,setCloseButtonIcon]=useState<string>(null);

  useEffect(()=>{
    getProperties()
    .then(result => {
      if (custId && custId != "anon") {
        setCloseButtonIcon(
          result.data.generalSettings.rootSite +
          "/" +
          custId +
          "/close-round.svg"
        );
      } else {
        setCloseButtonIcon(
          result.data.generalSettings.rootSite + "/close-round.svg"
        );
      }
    });
  },[custId])
  

  if (closeCurveText) {
    return (
      <div className='novaBotAttentionGrabberCurveContainer'>
        <img
          src={imgSrc}
          alt={imgAlt}
          height={imgHeight}
          width={imgWidth}
          className={`${imgClass} novaBotLogo`}
          onClick={() => {
            props.onClickLogo && props.onClickLogo();
          }}
          {...imgProps}
        />
      </div>
    );
  }
  // for bot msg exit from botPropertys.json
  if (!curveTextEnabled===true){
    setCloseCurveText(true);
    onCloseText && onCloseText();
  }

  return (
    <div className='novaBotAttentionGrabberCurveContainer'>
      <img
        className='novaBotMsgExit'
        src={closeButtonIcon}
        alt='close button attentionGrabber Curve'
        onClick={() => {
          setCloseCurveText(true);
          onCloseText && onCloseText();
        }}
      />
      <TextCurve text={curveText} textCurveDirection={textCurveDirection} startTextOffset={startTextOffset}>
        <img
          src={imgSrc}
          alt={imgAlt}
          height={imgHeight}
          width={imgWidth}
          onClick={() => {
            props.onClickLogo && props.onClickLogo();
          }}
          className={`${imgClass} novaBotLogo`}
          {...imgProps}
        />
      </TextCurve>
    </div>
  );
}

export default React.memo(BotAttentionGrabberCurve);

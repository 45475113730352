import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { UserAgentApplication } from "msal";
import { ImplicitMSALAuthenticationProvider } from "@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider";
import { MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions";
import { Client } from "@microsoft/microsoft-graph-client";
import "isomorphic-fetch";
import { getProperties } from "./localApi";
import "regenerator-runtime/runtime";

//function for returning user initials icon
export async function getUserInitials(userName) {
  return new Promise((resolve, reject) => {
    resolve("https://eu.ui-avatars.com/api/?rounded=true&name=" + userName);
  });
}
//function for generating GUID for user
export function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export async function getAuth() {
  const propertyObj = await getProperties();
  const properties = propertyObj.data;
  // Msal Configurations
  const authUrl = properties.generalSettings.authUrl;
  const clientId = properties.generalSettings.clientId;

  const config = {
    auth: {
      authority: authUrl,
      clientId: clientId,
      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?

      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };
  const graphScopes = ["user.read"];
  // Options
  const tokenRefreshUri =
    properties.generalSettings.rootSite +
    "/auth.html";
  const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: tokenRefreshUri,
  };
  // Authentication Parameters
  const authenticationParameters = {
    scopes: graphScopes,
  };

  //create auth provider object for login and export
  const AuthProviderLogin = new MsalAuthProvider(
    config,
    authenticationParameters,
    options
  );

  //create user agent application for implicit authentication in client
  const msalApplication = new UserAgentApplication(config);
  const iOptions = new MSALAuthenticationProviderOptions(graphScopes);
  const authProvider = new ImplicitMSALAuthenticationProvider(
    msalApplication,
    iOptions
  );

  const authOptions = {
    authProvider, // An instance created from previous step
  };

  const GraphClient = Client.initWithMiddleware(authOptions);

  const getUserDetails = async () => {
    try {
      let userDetails = await GraphClient.api("/me").get();
      console.log(userDetails);
      return userDetails;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getUserGraphPhoto = async (userId, userName) => {
    try {
      let userPhoto = await GraphClient.api(
        "/users/" + userId + "/photo/$value"
      ).get();
      // Always return a Promise
      return new Promise((resolve, reject) => {
        //read blob into a file
        const fileReaderInstance = new FileReader();
        fileReaderInstance.readAsDataURL(userPhoto);
        fileReaderInstance.onloadend = function (e) {
          resolve(e.target.result);
        };
      });
    } catch (error) {
      //if retrieving user icon fails for whatever reason - send initials icon
      return await getUserInitials(userName);
    }
  };
  return {
    AuthProviderLogin,
    GraphClient,
    options,
    getUserDetails,
    getUserGraphPhoto,
    getUserInitials,
  };
}
